html, body, #root {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.efm_logo {float: right;}

.nav {float: left;margin-left: 50px;}

.nav ul {margin: 0;padding: 0; overflow: hidden;}

.nav a {color: rgb(18, 81, 125);}

.header .logout {background-image: url('../../assets/icons_logout.png');width: 23px;height: 22px;display: inline-block;margin: 0 7px -6px 0;background-size:cover;background-position:center;background-repeat:no-repeat;}

.header .message {background-image: url('../../assets/icons_fav.png');width: 23px;height: 22px;display: inline-block;margin: 0 7px -6px 0;background-size:cover;background-position:center;background-repeat:no-repeat;}

.header .site{margin: 0 7px -6px 0;}

.nav .ant-badge{top:-5px;}

.nav .anticon-environment:before{font-size: 23px;}

.nav li {
  float: left;
  // display: inline-block;
  margin: 0 18px;
}
.nav li:first-child {
  margin-left: 0;
}

.nav .ant-avatar {margin: 20px 8px 24px 0;}

.ant-layout {
  height: 100%;
  .ant-layout-content {
    min-height: auto !important;
  }
}

.trigger {
  font-size: 18px !important;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color .3s;
}

.trigger:hover {
  color: #1890ff;
}

.trigger {float: left;}

.home-link {
  a {
    color: rgba(255, 255, 255, 0.65);
  }
}

//.ant-menu-item-selected a {
//  color: #fff
//}

.content-wrapper {
  margin: 24px 16px;
  padding: 24px;
  background: #fff;
  min-height: 280px
}

.header {
  height: 64px;
  padding: 0 12px 0 0;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, .08);
  position: relative;
}

.user-menu {
  padding: 0 10px;
  // &:hover {
  //   background: #e6f7ff;
  // }
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #363e4f;
}


@import '~antd/lib/style/themes/default.less';
@ease-in-out-circ: cubic-bezier(0.78, 0.14, 0.15, 0.86);
.logo {
  height: 64px;
  position: relative;
  line-height: 64px;
  padding-left: (@menu-collapsed-width - 32px) / 2;
  transition: all 0.3s;
  background: #fff;
  overflow: hidden;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  img {
    display: inline-block;
    vertical-align: middle;
    height: 32px;
  }
  h1 {
    color: rgba(0,0,0,.85);
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;
    margin: 0 0 0 12px;
    font-family: 'Myriad Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: 600;
  }
}

.sider {
  min-height: 100vh;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  position: relative;
  z-index: 10;
  &.ligth {
    background-color: white;
    .logo {
      background: white;
      h1 {
        color: #002140;
      }
    }
  }
}

.icon {
  width: 14px;
  margin-right: 10px;
}

:global {
  .drawer .drawer-content {
    background: #001529;
  }
  .ant-menu-inline-collapsed {
    & > .ant-menu-item .sider-menu-item-img + span,
    &
      > .ant-menu-item-group
      > .ant-menu-item-group-list
      > .ant-menu-item
      .sider-menu-item-img
      + span,
    & > .ant-menu-submenu > .ant-menu-submenu-title .sider-menu-item-img + span {
      max-width: 0;
      display: inline-block;
      opacity: 0;
    }
  }
  .ant-menu-item .sider-menu-item-img + span,
  .ant-menu-submenu-title .sider-menu-item-img + span {
    transition: opacity 0.3s @ease-in-out, width 0.3s @ease-in-out;
    opacity: 1;
  }
}

    .ant-avatar {
      margin: 20px 8px 20px 0;
      color: @primary-color;
      background: rgba(255, 255, 255, 0.85);
      vertical-align: middle;
    }

  .ant-layout-sider {background:#fff;}

