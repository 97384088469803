:global(.rightItem) {
    height: 100%;
}

.root {
    display: flex;
    width  : 576px;
    height : 370px;
}

.left {
    margin-right: 10px;
    padding     : 12px 16px;
    font-weight : 500;
    user-select : none;
    border-right: 1px solid #e8e8e8;
    width       : 245px;
}

.leftHeader {
    width     : 212px;
    text-align: center;
}

.leftContent {
    margin-top    : 15px;
    display       : flex;
    flex-direction: column;
    align-items   : center;
    justify-items : center;
    height        : 320px;
    overflow      : auto;
}

.projectItem {
    width           : 210px;
    overflow        : hidden;
    text-overflow   : ellipsis;
    white-space     : nowrap;
    height          : 40px;
    line-height     : 40px;
    /* text-align   : center; */
    display         : flex;
    justify-content : space-between;
}

.title {
    cursor       : pointer;
    overflow     : hidden;
    text-overflow: ellipsis;
    white-space  : nowrap;
    max-width    : 180px;
}

/* tab */
.right {
    overflow       : hidden;
    width          : 100%;
    height         : 370px;
    /* margin-right: 16px; */
}

.rightItem {
    height         : 323px;
    display        : flex;
    flex-direction : column;
    justify-content: space-between;
}

.checked {
    color: #2697ff;
}

.handleBtnGroup {
    display        : flex;
    justify-content: space-around;
}

.readBtn {
    flex            : 1;
    border-right    : 1px solid #c2c1c1;
    background-color: #f5f4f4;
    padding         : 3px 4px;
    cursor          : pointer;
    margin-bottom   : 10px;
    text-align      : center;
}

.delBtn {
    flex            : 1;
    cursor          : pointer;
    background-color: #f5f4f4;
    padding         : 3px 4px;
    margin-bottom   : 10px;
    text-align      : center;
}

.empty {
    line-height: 210px;
}

/* notice */
.noticeContent {
    display: flex;
}

.noticeItemLeft {
    margin-right: 10px;
}

.noticeItemTitle {
    margin-top   : 2px;
    height       : 30px;
    line-height  : 30px;
    width        : 260px;
    overflow     : hidden;
    white-space  : nowrap;
    text-overflow: ellipsis;
    word-break   : break-all;
    word-wrap    : break-word;
    color        : black;
}

.itemContent {
    width        : 260px;
    height       : 20px;
    line-height  : 20px;
    overflow     : hidden;
    white-space  : nowrap;
    text-overflow: ellipsis;
    word-break   : break-all;
    word-wrap    : break-word;
    font-size    : 12;
}

.todoContent {
    display        : flex;
    justify-content: space-between;
}

.todoItemLeft {}

.todoItemTitle {
    cursor       : pointer;
    width        : 240px;
    overflow     : hidden;
    white-space  : nowrap;
    text-overflow: ellipsis;
    word-break   : break-all;
    word-wrap    : break-word;
    color        : black;
}

.todoItemContent {
    width        : 240px;
    height       : 20px;
    line-height  : 20px;
    overflow     : hidden;
    white-space  : nowrap;
    text-overflow: ellipsis;
    word-break   : break-all;
    word-wrap    : break-word;
    font-size    : 12;
}

.todoItemRight {
    /* margin-right: 20px; */
    display    : flex;
    align-items: center;
}

.delTodoBtn {
    cursor          : pointer;
    font-size       : 12;
    padding         : 3px 4px;
    cursor          : pointer;
    margin-bottom   : 10px;
    text-align      : center;
    background-color: #f5f4f4;
}